const SEO = {
  metaTitle: 'transformamos ideias, produtos, desafios, negócios.',
  metaDescription:
    'Mais do que um fornecedor de tecnologia, transformamos e descomplicamos negócios. Somos um ateliê de software que desenvolve produtos e soluções handmade que fazem diferença desde a primeira entrega.',
  ogTitle: 'transformamos ideias, produtos, desafios, negócios.',
  ogDescription:
    'Mais do que um fornecedor de tecnologia, transformamos e descomplicamos negócios. Somos um ateliê de software que desenvolve produtos e soluções handmade que fazem diferença desde a primeira entrega.',
  ogType: 'website',
  ogImage: {
    url: 'https://media.graphassets.com/kZ9jGzlVTBOy31rmonj6'
  }
};

export default SEO;

import React from 'react';
import { Link } from 'gatsby';
import Button from 'components/Form/Button';
import { StyledWrapper } from 'assets/global/styled';
import {
  StyledNotFound,
  StyledNotFoundContainer,
  StyledNotFoundTitle,
  StyledNotFoundContent
} from './styled';

const NotFound = ({ page, pageContext }) => {
  const { title, content, callToAction } = page;
  const { baseURL } = pageContext;
  return (
    <StyledNotFound>
      <StyledWrapper>
        <StyledNotFoundContainer>
          <StyledNotFoundTitle>{title}</StyledNotFoundTitle>
          <StyledNotFoundContent>
            <p>{content}</p>
            <Button
              to={baseURL}
              as={Link}
              title={callToAction}
              callToAction={true}
            >
              {callToAction}
            </Button>
          </StyledNotFoundContent>
        </StyledNotFoundContainer>
      </StyledWrapper>
    </StyledNotFound>
  );
};

export default NotFound;

import styled from 'styled-components';
import { StyledWrapper } from 'assets/global/styled';
import colors from 'assets/global/colors';
import fonts from 'assets/global/fonts';

export const StyledNotFound = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  height: 100vh;
  background-image: url('https://media.graphassets.com/resize=w:1200,fit:clip/output=format:webp/compress/gZD2mJRBToCML13CbEBa');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  ${StyledWrapper} {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
`;

export const StyledNotFoundContainer = styled.div`
  display: block;
  width: 100%;
  text-align: center;
`;

export const StyledNotFoundTitle = styled.h4`
  margin: 0;
  padding: 0;
  font-family: ${fonts.primary};
  font-size: 200px;
  font-weight: 700;
  color: ${colors.white};
  text-transform: uppercase;

  @media screen and (max-width: 520px) {
    font-size: 120px;
  }

  @media screen and (max-width: 460px) {
    font-size: 80px;
  }
`;

export const StyledNotFoundContent = styled.div`
  display: block;
  text-align: center;

  p {
    color: ${colors.white};
  }

  a {
    margin-top: 20px;
  }
`;

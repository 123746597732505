import React from 'react';
import SEO from 'components/SEO';
import { graphql } from 'gatsby';
import NotFound from 'templates/404';
import Header from 'components/Header';
import Footer from 'components/Footer';
import seo_items from 'templates/404/SEO/constants';

const NotFoundPage = ({
  data: {
    ateliware: { page, headerMenu, footerMenu }
  },
  pageContext
}) => {
  const { locale } = pageContext;
  return (
    <React.Fragment>
      <SEO
        meta_title={seo_items?.metaTitle}
        meta_description={seo_items?.metaDescription}
        og_title={seo_items?.ogTitle}
        og_description={seo_items?.ogDescription}
        og_type={seo_items?.ogType}
        og_image={seo_items?.ogImage?.url}
        lang={locale}
      />
      <Header menu={headerMenu} pageContext={pageContext} />
      <NotFound page={page} pageContext={pageContext} />
      <Footer footerMenu={footerMenu} pageContext={pageContext} />
    </React.Fragment>
  );
};

export const NotFoundQuery = graphql`
  query NotFoundPageQuery($idPage: ID!, $locale: [GCMS_Locale!]!) {
    ateliware {
      page(where: { id: $idPage }, locales: $locale) {
        title
        content
        callToAction
        seo {
          metaTitle
          metaDescription
          ogTitle
          ogType
          ogDescription
          ogImage {
            url
          }
        }
      }
      headerMenu: menu(where: { title: "header" }, locales: $locale) {
        locale
        callToAction
        menuItems {
          title
          slug
          locale
          goTo
          externalLink
          subMenuItems {
            title
            slug
            locale
            goTo
            externalLink
          }
        }
      }
      footerMenu: menu(where: { title: "footer" }, locales: $locale) {
        locale
        callToAction
        menuItems {
          title
          slug
          locale
          goTo
          externalLink
        }
      }
    }
  }
`;

export default NotFoundPage;
